// Relativ path to images folder (used in the image-url() function)
$imagePath: "../images/" !default;

// +++++ Font settings
// Font size of main content (in px)
$baseFontSize: 16px;

// Line height of main content (in px)
$baseLineHeight: 24px;

// Headline font sizes (in px)
$h1Size: 32px;
$h2Size: 28px;
$h3Size: 24px;
$h4Size: 22px;
$h5Size: 18px;
$h6Size: 16px;

// Font stacks: $mainFont for simple content, $displayFont for headlines etc., $iconFont for icons
$mainFont:    "Blinker", sans-serif;
$displayFont: "Blinker", sans-serif;
// $iconFont:   sans-serif;
$iconFont:   "icomoon";

// +++++ Design & Layout
// Colours for your design.
$primary:   #726A5B;
$secondary: #EDE8DD;
$light:     #fff;
$medium:    #F2F2F0;
$dark:      #262D3B;
$border:    #bbb;
$alert:     #D85D00;
$darkgrey:  #1d1d1b;
$blue:      #0099ff;
$textgrey:  #dadada;

// Horizontal padding left and right of grid columns
$baseGap: 18px;

// Grid row max-width
$rowMaxWidth: 1600px;

// Grid columns
$columnCount: 12;

// Standard border for your design
$baseBorder: 1px solid $border;

// Standart outline styles for focus states
$outline: 1px dotted $alert;

// Assume a different base font size for breakpoint em calculation (default: 16px)
$bpContext: 16px;

// +++++ Miscellaneous
// text-indent value when using hideText(): "right" = positive value, "left" = negative value
$hideTextDirection: "right";

// $iconMap: (
//     times: "×",
//     minus: "-",
//     angle-right: "❯",
//     plus: "+",
//     angle-up: "↑",
//     exclamation: "!"
// );

// EXAMPLE FOR USE WITH ICONFONT
$iconMap: (
    times: "\f00d",
    minus: "\f068",
    angle-right: "\f105",
    plus: "\f067",
    angle-up: "\f106",
    exclamation: "\f06a",
    home: "\f015",
    lock: "\f023",
    map: "\f041",
    phone: "\f095",
    mail: "\f0e0",
    mobile: "\f10b",
    clock: "\e94f",
    key: "\e98d",
    tool: "\e991"
);


/*  Grid Map 
    Define your breakpoints and grid classes below.
    Only generate the gridclasses you really need to reduce filesize.
    example:
    tiny: ( <-- "breakpoint name"
        width:  em(480px, $bpContext), <-- "breakpoint width"
        cols: (5,6,12), <-- "columns" result generates .tiny-5, .tiny-6 and .tiny-12
        prefix: (2,4), <-- "prefix" result generates .tiny-prefix-2 and .tiny-prefix-4
        suffix: (6,8), <-- "suffix" result generates .tiny-suffix-6 and .tiny-suffix-8
        center: (align-item: center), <-- resutl generates .tiny-center {align-item: center} for breakpoint tiny
        flex: (display: flex) <-- resutl generates .tiny-flex {display: flex} for breakpoint tiny
    )
*/
$gridMap: (
    default: (
        width: em(0px, $bpContext),
        cols: (4,8)
    ),
    tiny: (
        width:  em(480px, $bpContext)
    ),
    small: (
        width:  em(640px, $bpContext)
    ),
    medium: (
        width:  em(760px, $bpContext),
        cols: (4,5,6,7,8)
    ),
    large: (
        width:  em(992px, $bpContext),
        cols: (2,3,4,5,6,7,9,10),
        prefix: (6),
        suffix: (6)

    ),
    giant: (
        width:  em(1200px, $bpContext),
        cols: (2,3,4,5,6,8,9,10,12),
        suffix: (1)

    ),
    cut: (
        width: em($rowMaxWidth, $bpContext)
    ),
    huge: (
        width:  em(1364px, $bpContext)
    ),
    full: (
        width:  em(1520px, $bpContext)
    )
);