

// HEADER
//////////////////////////////

#header {
	position: relative;
	width: 100%;
	margin-bottom: 3rem;
	padding: rem(10px) rem($baseGap);
	margin-top: 6rem;
	@include breakpoint(large) {
		padding: 0rem 0 4rem;
		margin-top: 14rem;
	}
	.claimColFirst {
		padding: 0;
		@include breakpoint(large) {
			padding-left: $baseGap;
		}
		.claimText {
			margin-top: 1rem;
			color: $light;
			font-size: 1.5rem;
			line-height: 2rem;
			@include breakpoint(small) {
				font-size: 2rem;
				line-height: 3rem;
			}
			@include breakpoint(large) {
				margin-top: 2rem;
				font-size: 3rem;
				line-height: 4rem;
			}
			@include breakpoint(huge) {
				font-size: 4.5rem;
				line-height: 6rem;
			}
		}
		.claimMeisterbetrieb {
			max-width: 85%;
			width: auto;
			margin: 0;
			padding: .75rem 0;
			@include breakpoint(small) {
				max-width: 100%;
			}
			@include breakpoint(medium) {
				margin: 2rem 0;
				padding: 0 0;
			}
		}
		.gehtnichtgibtsnicht {
			width: auto;
			margin: 0;
			max-width: 100%;
			padding: .75rem 0;
			
			@include breakpoint(medium) {
				margin: 2rem 0;
				padding: 0 1rem 0 0;
			}	
			@include breakpoint(giant) {
				padding: 0 0 0 0;
			}
		}
		.btn {
			background: $blue;
			color: $light;
			margin-right: 1rem;
			margin-top: 1rem;
			min-width: rem(200px);
			@include breakpoint(large) {
				margin-top: 2rem;
			}
			.phone {
				text-decoration: none;
				font-size: 1.25rem;
				&::before {
					content: map-get($iconMap, phone);
					font-family: $iconFont;
					padding-right: .5rem;
					display: inline-block;
					font-size: 1.25rem;
					vertical-align: middle;
				}
			}
			.mobilePhone {
				text-decoration: none;
				font-size: 1.25rem;
				&::before {
					content: map-get($iconMap, mobile);
					font-family: $iconFont;
					padding-right: .5rem;
					display: inline-block;
					font-size: 1.25rem;
				}
			}
		}
		.btnWhite {
			background: $light;
			color: $blue;
			margin-right: 0;
			margin-bottom: 1rem;
			border: none;
			@include breakpoint(large) {
				margin-bottom: 0;
			}
		}
	}
	.swiper-container {
		@include breakpoint(medium) {
			position: absolute;
		    right: 0;
		    top: 25%;
		    width: 50%;
		    @media screen and (min-width: 1750px) {
		    	top: 0;
		    }
			.swiper-wrapper {
				.swiper-slide {
					img {
						width: auto;
					}
				}
			}
			.swiper-button-prev, .swiper-button-next {
				opacity: 0.7;
				transition: .5s ease-in-out;
				&:hover {
					opacity: 1;
				}
			}
			.swiper-button-next {
				@media screen and (min-width: 2200px) {
					right: rem(155px);
				}
				@media screen and (min-width: 2400px) {
					right: rem(195px);
				}
			}
		}
	}
}


// MAIN CONTENT
//////////////////////////////
body {
	&:not(.index) {
		main {
			background: #fff;
			padding: 4rem 0;
			h1, h2, h3, h4, h5, h6, p, strong, li, dd, dt, label,  {
				color: $darkgrey;
			}
			dd, dt {
				background: $textgrey;
			}
			a {
				&:hover {
					color: $darkgrey;
				}
			}
		}
	}
}
main {
	display: block;

	p,
	ol,
	ul,
	dl,
	table {
		@extend .marginBottom;
	}

	ul:not(.unstyledList),
	dl:not(.unstyledList) {
		@extend .styledList;
	}

	.googleMaps {
		@extend .videoContainer;

		@include breakpoint(medium) {
			height: rem(400px);
		}
	}
}

#privacyPolicy
{
	a {
		display: inline-block; 
		word-break: break-all;
	}
}

// SECTIONS
//////////////////////////////

.sectionMargin {
	margin: 2.5rem 0;
	@include breakpoint(small) {
		margin: 4rem 0;
	}
	@include breakpoint(huge) {
		margin: 8rem 0;
	}
}
.sectionMw {
	@media screen and (min-width: 1921px) {
		max-width: 2000px !important;
		margin: 8rem auto 8rem auto !important;
	}
}
.whiteContainer {
	padding: 1.25rem 0;
	background: #fff;
	@include breakpoint(small) {
		padding: 2rem 0;
	}
	@include breakpoint(huge) {
		padding: 4rem 0;
	}
	.sectionMargin {
		margin: 1.25rem 0;
		@include breakpoint(small) {
			margin: 2rem 0;
		}
		@include breakpoint(huge) {
			margin: 4rem 0;
		}
	}	
.sectionMw {
	@media screen and (min-width: 1921px) {
			max-width: 2000px !important;
			margin: 4rem auto 4rem auto !important;
		}
	}
}
#sOne {
	position: relative;
	.sOneFirstRow {
		@include breakpoint(large) {
			min-height: rem(675px);
		}
	}
	.col {
		h1 {
			span {
				display: block;
				font-weight: 300;
			}
		}
		p, li {
			@include breakpoint(full) {
				font-size: 1.25rem;
			}
		}
	}
	.imgContainer {
		position: relative;
		max-width: rem(500px);
		margin: 2.5rem auto 0 $baseGap;
		@include breakpoint(small) {
			margin: 4rem auto 0 $baseGap;
		}
		@include breakpoint(large) {
			margin: 1rem auto 0 auto;
			margin-bottom: 0;
			position: absolute;
			right:0;
			top: 25%;
		}
		@include breakpoint(giant) {
			max-width: rem(550px);
			top: 20%;
		}
		@media screen and (min-width: 1650px) {
			top: 0;
			max-width: 100%;
		}
	}
	.btn {
		max-width: rem(250px);
		min-width: rem(200px);
		background: $blue;
		color: $light;
		margin-right: 1rem;
		transition: .5s ease-in-out;
		&:hover {
			background: $light;
			color: $blue;
		}
	}
	.btnSchlosser {
		&::before {
			content: map-get($iconMap, tool);
			font-family: $iconFont;
			padding-right: .5rem;
			vertical-align: middle;
		}
	}
	.btnSchluesseldienst {
		margin-top: 1rem;
		@include breakpoint(medium) {
			margin-top: 0;
		}
		&::before {
			content: map-get($iconMap, key);
			font-family: $iconFont;
			padding-right: .5rem;
			vertical-align: middle;
		}
	}

}
#sTwo {
	position: relative;
	.row {
		@include breakpoint(large) {
			min-height: rem(675px);
		}
	}
	.imgContainer {
		position: relative;
		max-width: rem(500px);
		margin: 0 auto 2.5rem $baseGap;
		@include breakpoint(small) {
			margin: 0 auto 4rem $baseGap;
		}
		@include breakpoint(large) {
			margin: 1rem auto 0 auto;
			margin-bottom: 0;
			position: absolute;
			left:0;
			top: 25%;
		}
		@include breakpoint(giant) {
			max-width: rem(550px);
			top: 20%;
		}
		@media screen and (min-width: 1650px) {
			top: 0;
			max-width: 100%;

		}
	}
	.col {
		display: flex;
		flex-direction: column;
		justify-content: center;

		ul{
			display: flex;
			flex-wrap: wrap;
			
			li{
				color: $dark;
				width: 100%;

				@include breakpoint(tiny) {
					width: calc(50% - #{$baseGap});
					margin-right: $baseGap;
				}
			}
		}
		h2 {
			color: $darkgrey;
			span {
				color: $darkgrey;
				display: block;
				font-weight: 300;
			}
		}
		p {
			color: $darkgrey;
			@include breakpoint(full) {
				font-size: 1.25rem;
			}
		}
		strong {
			color: $darkgrey;
		} 
		.btn {
			max-width: rem(250px);
			min-width: rem(200px);
			background: $blue;
			color: $light;
			margin-right: 1rem;
			transition: .5s ease-in-out;
			border: 1px solid $blue;
			&:hover {
				background: $light;
				color: $blue;
			}
			&::before {
				content: map-get($iconMap, home);
				font-family: $iconFont;
				padding-right: .5rem;
				vertical-align: middle;
			}
		}
	}
}
#sThree {
	position: relative;
	.row {
		@include breakpoint(large) {
			min-height: rem(675px);
		}
	}
	.col {
		display: flex;
		flex-direction: column;
		justify-content: center;
		h3 {
			span {
				display: block;
				font-weight: 300;
			}
		}
		p {
			@include breakpoint(full) {
				font-size: 1.25rem;
			}
		}
		.btn {
			max-width: rem(250px);
			min-width: rem(200px);
			background: $blue;
			color: $light;
			margin-right: 1rem;
			transition: .5s ease-in-out;
			&::before {
				content: map-get($iconMap, home);
				font-family: $iconFont;
				padding-right: .5rem;
				vertical-align: middle;
			}
			&:hover {
				background: $light;
				color: $blue;
			}
		}
	}
	.imgContainer {
		position: relative;
		max-width: rem(500px);
		margin: 2.5rem auto 0 $baseGap;
		@include breakpoint(small) {
			margin: 4rem auto 0 $baseGap;
		}
		@include breakpoint(large) {
			margin: 1rem auto 0 auto;
			margin-bottom: 0;
			position: absolute;
			right:0;
			top: 25%;
		}
		@include breakpoint(giant) {
			max-width: rem(550px);
			top: 20%;
		}
		@media screen and (min-width: 1650px) {
			max-width: 100%;
			top: 0;
		}
	}
}
#sFour {
	#particles-js {
		background-color: #fff !important;
		height: 60% !important;
		
		@include breakpoint(medium) {
			canvas {
				height: 100%;
			}
		}
	}
	.row {
		@include breakpoint (small) {
			padding: 2.5rem 0 3.5rem;
		}
		@include breakpoint(large) {
			padding: 4rem 0 0;
		}
	}
	.col {
		h4 {
			color: $darkgrey;
			text-align: center;
			font-size: 1.5rem;
			@include breakpoint(large) {
				font-size: 2.5rem;
			}
			span {
				display: block;
				line-height: 2.25rem;
				font-size: 1.25rem;
				font-weight: 300;
			}
		}
	}
}
#sFive {
	.sFiveFirstRow {
		@include breakpoint(small) {
			padding: 2.5rem 0 1rem;
		}
		@include breakpoint(large) {
			padding: 7.5rem 0 0;
			body.internetExplorer &, body.edge & {
				padding: 4.5rem 0 0;
			}
		}
		@include breakpoint(huge) {
			padding: 4.5rem 0 0;
			body.internetExplorer &, body.edge & {
				padding: 2.5rem 0 0;
			}
		}
	}
	.sFiveCol {
		display: flex;
		justify-content: center;
		h5 {
			text-align: center;
			font-size: 1.5rem;
			line-height: 2.25rem;
			@include breakpoint(large) {
				font-size: 2.5rem;
			}
			span {
				font-size: 1.25rem;
				line-height: 2.25rem;
				display: block;
				font-weight: 300;
			}
		}
	}
	.formCol {
		padding-top: 2rem;
		input, textarea {
			background: transparent;
			&::placeholder {
				color: $textgrey;
			}
		}
		label[for="Rueckruf"] {
			width: 90%;
			display: inline-block;
			padding-top: 4rem;
		}
		fieldset {
			margin-bottom: .75rem !important;
		}
		textarea {
			min-height: 10rem;
		}
		small {
			color: $blue;
			display: block;
		}
		#Rueckruf {
			margin: 0;
			width: rem(15px);
			margin-top: 3rem;
		}
		.btn {
			min-width: rem(200px);
			max-width: rem(250px);
			background: $blue;
			color: $light;
			transition: .5s ease-in-out;
			margin-bottom: 0;
			&::before {
				content: map-get($iconMap, mail);
				font-family: $iconFont;
				padding-right: .5rem;
			}
			&:hover {
				background: $light;
				color: $blue;
			}
		}
	}
	.gMapCol {
		margin-top: 2rem;
		position: relative;
		.overlay {
			&.open {
				display: flex;
			    flex-direction: column;
			    justify-content: center;
			    opacity: 1;
			    visibility: visible;
			    align-items: center;
				position: absolute;
				width: calc(100% - 36px);
				min-height: rem(450px);
				height: 100%;
				background:rgba(2,57,94, 0.7);
				.overlayCompanyName {
					font-size: 1.35rem;
					@include breakpoint(large) {
						font-size: 2rem;
					}
					color: $light;
					&::before {
						content: map-get($iconMap, map);
						font-family: $iconFont;
						position: absolute;
						top: 15%;
						font-size: 2.5rem;
						@include breakpoint(medium) {
							font-size: 2.75rem;
							top: 25%;
						}
						@include breakpoint(large) {
							top: 27.5%;
							font-size: 3rem;
						}
						left: 50%;
						transform: translate(-50%, -30%);
					}
				}
				.overlayPhone {
					font-size: 1.35rem;
					@include breakpoint(large) {
						font-size: 2rem;
					}
					margin-bottom: 1rem;
					&::before {
						content: map-get($iconMap, phone);
						font-family: $iconFont;
						padding-right: .5rem;
						font-size: 1.5rem;
					}

				}
				.overlayMobile {
					font-size: 1.35rem;
					@include breakpoint(large) {
						font-size: 2rem;
					}
					margin-bottom: 1rem;
					&::before {
						content: map-get($iconMap, mobile);
						font-family: $iconFont;
						padding-right: .5rem;
						font-size: 1.5rem;
					}

				}
				.overlayMail {
					font-size: 1.35rem;
					color: $light;
					transition: .5s ease-in-out;
					text-decoration: none;
					&:hover {
						color: $blue;
					}
					@include breakpoint(large) {
						font-size: 2rem;
					}
					margin-bottom: 1rem;
					&::before {
						content: map-get($iconMap, mail);
						font-family: $iconFont;
						padding-right: .5rem;
						display: inline-block;
						font-size: 1.5rem;
					}
				}
				.btn {
					color: #02395E;
					background: $light;
					transition: .5s ease-in-out;
					&:hover {
						background: #02395E;
						color: $light;
					}
				}
			}
			&.closed {
				opacity: 0;
				visibility: hidden;
				display: none;
			}
		}
		iframe {
			height: 100%;
			min-height: rem(450px);
		}
	}
}

// PAGE RELATED STYLING
//////////////////////////////
body.schluesseldienst, body.sicherheitSmarthome, body.schlosser, body.hausverwaltungen {
	@include breakpoint(full) {
		p, li {
			font-size: 1.25rem;
		}
	}
}
body.sicherheitSmarthome {
	.jabloImgCol {

		.jablotronImg {
			max-width: 100%;
			width: auto;
		}
	}
	.jabloTextCol {
		display: flex;
		align-items: center;
	}
}
body.hausverwaltungen {
	.iloqImgCol {
		display: flex;
		.iloqImg {
			max-width: 100%;
			width: auto;
			margin-bottom: 1rem;
			@include breakpoint(medium) {
				margin-bottom: 0;
			}
		}
	}
	.iLoqContentCol {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
}
body.anfahrt {
	form.default input {
		color: $darkgrey;
	}
	button[type="submit"] {
		margin-top: 1rem;
		border: 1px solid $blue;
		&::before {
			content: map-get($iconMap, map);
			font-family: $iconFont;
			padding-right: .5rem;
			vertical-align: middle;
		}
	}
}
body.kontaktFormularbestaetigung {
	.btn {
		background: $blue;
		color: $light;
		transition: .5s ease-in-out;
		border: 1px solid $blue;
		&:hover {
			background: $light;
			color: $blue;
		}
	}
}
body.kontakt {
	form.default input {
		color: $darkgrey;
	}
	form.default textarea {
		color: $darkgrey;
	}
	.btn {
		border: 1px solid $blue;
	}
}
body.schluesseldienst {
	@media screen and (min-width: 760px) {
		ul {
			li {
				font-size: .8rem;
			}
		}
	}
	@media screen and (min-width: 825px) {
		ul {
			li {
				font-size: .9rem;
			}
		}
	}
	@media screen and (min-width: 900px) {
		ul {
			li {
				font-size: 1rem;
			}
		}
	}
	@media screen and (min-width: 1520px) {
		ul {
			li {
				font-size: 1.25rem;
			}
		}
	}
}
body.internetExplorer {


	#sOne {
		.sOneFirstRow {
			> .col {
				@include breakpoint(huge) {
					position: absolute;
					top: 10%;
				}
			}
		}
	}
	#sTwo {
		.row {
			> .col {
				@include breakpoint(large) {
					position: absolute;
					top: 25%;
				}
			}
		}
	}
	#sThree {
		.row {
			> .col {
				@include breakpoint(large) {
					position: absolute;
					top: 25%;
				}
			}
		}
	}
	@include breakpoint($break) {
		.metaColTwo {
			display: flex;
			justify-content: center;
			a {
				display: flex;
				justify-content: center;
				.branding {
					width: 75% !important;
				}
			}
		}
	}

}
// FOOTER
//////////////////////////////
body {
	&:not(.index) {
		#footer {
			margin-top: 0;
		}
	}
}
#footer {
	background: #fff;
	margin-top: 3rem;
	padding: 1rem 0;
	border-top: 1px solid $blue;

	.navi {
		display: flex;
		flex-direction: column;
		align-items: center;
		@include breakpoint(medium) {
			flex-direction: row;
			justify-content: space-between;
		}
		li {
			padding: 1.5rem 0;
			@include breakpoint(medium) {
				padding: 3rem 0;
				margin-right: 1rem;
			}
			a {
				text-decoration: none;
				color: $darkgrey;
				font-size: 1.5rem;
				@include breakpoint(medium) {
					font-size: 1rem
				}
				@include breakpoint(giant) {
					font-size: 1.5rem;
				}
				transition: .5s ease-in-out;
				&:hover, &.active {
					color: $blue;
				}
			}
		}
	}
	.medium-4 {
		display: flex;
		align-items: center;
		justify-content: center;
		a {
			text-decoration: none;
			font-size: 1rem;
			&:hover {
				color: $darkgrey;
			}
			@include breakpoint(tiny) {
				font-size: 1.5rem;
			}
			@include breakpoint(medium) {
				font-size: 1rem
			}
			@include breakpoint(giant) {
				font-size: 1.5rem;
			}
			display: flex;
			align-items: center;
		}
		img {
			max-width: 100%;
			width: auto;
			padding-left: .5rem;
			opacity: 0.8;
			transition: .3s ease-in-out;
			&:hover {
				opacity: 1;
			}
		}
	}
}
#sFour {
	/* ---- reset ---- */ body{ margin:0; font:normal 75% Arial, Helvetica, sans-serif; } canvas{ display: block; vertical-align: bottom; } /* ---- particles.js container ---- */ #particles-js{ position:absolute; width: 100%; height: 100%; background-color: #1d1d1b; background-repeat: no-repeat; background-size: cover; background-position: 50% 50%; } /* ---- stats.js ---- */ .count-particles{ background: #000022; position: absolute; top: 48px; left: 0; width: 80px; color: #13E8E9; font-size: .8em; text-align: left; text-indent: 4px; line-height: 14px; padding-bottom: 2px; font-family: Helvetica, Arial, sans-serif; font-weight: bold; } .js-count-particles{ font-size: 1.1em; } #stats, .count-particles{ -webkit-user-select: none; margin-top: 5px; margin-left: 5px; } #stats{ border-radius: 3px 3px 0 0; overflow: hidden; } .count-particles{ border-radius: 0 0 3px 3px; }
}
.line {
    display: flex;
    width: 100%;
    color: #ccc;
    border: .5px solid;
    opacity: 0.25;
    margin: 1rem 0 2rem 0;
}
.noMobileMb {
	margin-bottom: 0;
	@include breakpoint(medium) {
		margin-bottom: 1.5rem;
	}
}

.aktion {
	background-color: #dadada;
	padding: 25px;
	text-align: center;
	margin: auto;
	width: 93%;
	margin-top: 25px;

	@include breakpoint(medium) {
		width: 80%;
	}

	@include breakpoint(large) {
		width: 55%;
	}


	span {
		font-size: 40px;
		padding: 6px 11px;
		color: black !important;
		display: block;
		font-weight: bold;
	}

	.zuseatzlich {
		font-size: 20px;
		padding: 0px 2px;
	}

	h2 {
		color: $blue !important;
		font-size: 30px !important;
	}

	a {
	background-color: $blue;
	}
}

.akt {
		text-align: center;
	}

	.popup-row{
	display: none;
	position: fixed;
	z-index: 100000;
	margin: auto;
	height: 100%;
	width: 100%;
	justify-content: center;
	align-items: center;
	top: 0%;

	body.index &{
		display: flex;
	}

	.popup{
		width: auto;
		height: auto;
		max-width: 900px;
		background-color: #fff;
		display: flex;
		flex-direction: column;
		border: solid 2px $primary;
		justify-content: center;
		align-items: center;
		text-align: center;
		padding: 30px 45px;
		position: relative;
		width: 95%;

		@include breakpoint(small) {
			padding: 40px 60px;
			width: 60%;
		}

		@include breakpoint(medium) {	
			padding: 80px 120px;
			font-size: 1.2rem;
			line-height: 30px;
		}

		}
	}

.aktionpop {
	
	p {
	color: black;
	}

	span {
		font-size: 40px;
		padding: 6px 11px;
		color: black !important;
		display: block;
		font-weight: bold;
	}

	h2 {
		color: $blue;

		@include breakpoint(large) {
		font-size: 38px;
		}

	}

	.btn {
		background-color: $blue;
	}

	.close {
		padding: 5px 13px;
		position: absolute;
    	right: 15px;
    	top: 15px;
	}
}

.swiper-container{
	&.unterseite{
		.swiper-wrapper{
			.swiper-slide{
				a{
					img{
						height: 330px;
						object-fit: cover;
						object-position: center;
					}
				}
			}
		}
	}
}
#abstandunten{
	@media screen and (max-width:992px){
		margin-bottom:2rem;
	}	
}