.marginBottom {
	margin-bottom: rem($baseLineHeight);
}

.highlight {
	color: $secondary;
}

.btn {
	@extend %animatedTransform;
	background: $dark;
	border: none;
	color: $light;
	cursor: pointer;
	display: inline-block;
	font-size: rem($baseFontSize);
	line-height: rem($baseLineHeight);
	padding: 0.8rem 1rem;
	text-align: center;
	text-decoration: none;
	font-family: $mainFont;

	&:hover,
	&:active,
	&:focus {
		background: lighten($dark, 15%);
		color: $light;
	}

	*[class^="icon"] {
		margin-left: 0.5rem;
		vertical-align: middle;
	}
}

.clear {
	clear: both;
	float: none;
}

.clearfix {
	@include pie-clearfix;
}

.hidden {
	display: none;
}

.hideText {
	@include hideText();
	display: inline-block;
	overflow: hidden;
	width: 0;
}

.floatLeft {
	float: left;
}

img.floatLeft {
	margin-right: 1rem;
}

.floatRight {
	float: right;
}

img.floatRight {
	margin-left: 1rem;
}

.fluid {
	height: auto;
	width: 100%;
}

.nonFluid {
	width: auto !important;
}

.alignLeft {
	text-align: left;
}

.alignRight {
	text-align: right;
}

.alignCenter {
	text-align: center;
}

.middle {
	align-items: center;
}

.wide {
	width: 100%;
	display: block;
}

/* 16:9 video resolutions */
.videoContainer {
	&:not(.videoTag) {
	  position: relative;
	  height: 0;
	  overflow: hidden;
	  padding-bottom: 56.25%;
	  position: relative;
  
	  iframe {
		border: none;
		height: 100%;
		position: absolute;
		width: 100%;
		top: 0;
		left: 0;
	  }
	}
  
	video {
	  width: 100%;
	  height: auto;
	}
  }

body {
	&:before {
	$content: "default:" + $bpContext;
	width: str_length($content) * 6pt + $baseGap * 2;

	@each $point, $width in $gridMap {
		$content: $content +
		"...." +
		$point +
		":" +
		nth(map-get(map-get($gridMap, $point), width), 1);

		@include breakpoint($point) {
		width: str_length($content) * 6pt + $baseGap * 2;
		}
	}

	content: "#{$content}";
	display: none !important; /* Prevent from displaying. */
	}

	&.devmode:before,
	&.debug:before {
	background: $alert;
	border-color: $alert;
	border-style: solid;
	border-width: 0 rem($baseGap);
	color: $light;
	display: block !important;
	font-family: Courier;
	font-size: 10pt;
	left: 0;
	line-height: 2.5;
	overflow: hidden;
	position: absolute;
	right: 0;
	text-align: center;
	top: 0;
	white-space: nowrap;
	z-index: 99999;
	}
	&:hover:before {
	opacity: 0.5;
	pointer-events: none;
	}
}

// Animations
%animatedTransform {
	transition: 0.3s;
}


.addressmain {
	display: flex!important;
}

.liste{
	margin-bottom: 1.75rem;
}