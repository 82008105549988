// GLOBAL SETTINGS
//////////////////////////////////////////////

$break: large; // Breakpoint – switch to Desktop version

// NAVIGATION
//////////////////////////////////////////////

$navBg: $darkgrey; // Navigation Background
$navColor: $light; // Navigation Link Color

@mixin navButton {
	// Default Link Styles
	padding: 1em rem($baseGap)/2;
	text-decoration: none;
	color: $navColor;
}
%buttonHover {
	// Link Hover
	color: $blue;
	background-color: none;
}
%buttonActive {
	// Link Active
	color: $blue;
	background-color: none;
}
%prevIcon {
	// Icon to previus Submenu
	font-family: $iconFont;
	content: map-get($iconMap, angle-right);
	transform: rotateY(180deg);

	// @include breakpoint($break) {
	// 	transform:rotate(90deg);
	// }
}
%nextIcon {
	// Icon to next Submenu
	font-family: $iconFont;
	content: map-get($iconMap, angle-right);

	@include breakpoint($break) {
		transform:rotate(90deg);
	}
}
$closeIcon: map-get($iconMap, times); // Icon to close Submenu

// Quickbar
//////////////////////////////////////////////

$barHeight: 100px; // height (in px)
$barPos: top; // position (top, bottom)
$barHide: true; // if bar hides on scroll (true, false)

$barBg: $darkgrey; // background-color
$barColor: $light; // children colors – texts and burgerbutton

//////////////////////////////////////////////
// NAVIGATION FUNCTIONALITY
//////////////////////////////////////////////

@import "../modules/ruffnav";

// Navigation Styles
////
@include pageNavi {
	background-color: $navBg;
	z-index: 1001;
	#metaRow {
		display: none;
	}
	nav {
		@include ul {
			&.navi {
				display: flex;
			    flex-direction: column;
			    align-items: center;
			    padding-top: 1rem;
			    min-height: 35rem !important;
				li {
					@include navButtons {
						@include navButton;
					}
					@include hasSub {
						@include sub {
							background: $navBg;

							&:before {
								padding: 1em rem($baseGap);
								background-color: darken($navBg, 6%);
							}
						}
					}
					@include navBack {
					}
					a {
						font-size: 1.25rem;
					}
				}
			}
		}
	}
}


// Navigation Desktop Styles
////
@include breakpoint($break) {
	@include desktopVersionFunctionality;

	#navigation {
		background: $darkgrey;
		box-shadow: 0px 20px 25px -20px rgba(0,0,0,0.75);
		#metaRow {
			display: flex;
			margin-top: .5rem;
			span {
				color: $textgrey;
			}
			.metaColOne {
				display: flex;
				align-items: center;
				.col {
					display: flex;
					flex-direction: column;
					.phone {
						@include breakpoint(giant) {
							font-size: .8rem;
						}
						@include breakpoint(huge) {
							font-size: 1rem;
						}
						&::before {
							content: map-get($iconMap, phone);
							font-family: $iconFont;
							padding-right: .5rem;
							vertical-align: middle;
						}
					}
					.mobilePhone {
						@include breakpoint(giant) {
							font-size: .8rem;
						}
						@include breakpoint(huge) {
							font-size: 1rem;
						}
						&::before {
							content: map-get($iconMap, mobile);
							font-family: $iconFont;
							padding-right: .8rem;
							vertical-align: middle;
						}
					}
					.email {
						@include breakpoint(giant) {
							font-size: .8rem;
						}
						@include breakpoint(huge) {
							font-size: .9rem;
						}
						@media screen and (min-width: 1430px) {
							font-size: 1rem;
						}
						color: $blue;
						text-decoration: underline;
						&:hover {
							color: $light;
						}
						&::before {
							content: map-get($iconMap, mail);
							font-family: $iconFont;
							padding-right: .5rem;
							text-decoration: none !important;
							display: inline-block;
							vertical-align: middle;
						}
					}
					.metaOpening {
						@include breakpoint(giant) {
							font-size: .8rem;
						}
						@include breakpoint(huge) {
							font-size: .9rem;
						}
						@media screen and (min-width: 1430px) {
							font-size: 1rem;
						}
						&::before {
							content: map-get($iconMap, clock);
							font-family: $iconFont;
							padding-right: .5rem;
							vertical-align: middle;
						}
					}
				}
				.metaColOneInnerOne {
					padding-right: .25rem;
					padding-top: 0;
					@include breakpoint(huge) {
						padding: 0 $baseGap;
					}

					.metaMap {
						font-size: .75rem;
						@include breakpoint(huge) {
							font-size: .9rem;
						}
						&::before {
							content: map-get($iconMap, map);
							font-family: $iconFont;
							padding-right: .5rem;
							vertical-align: middle;
						}
					}
					.metaMapAdd {
						text-indent: 1rem;
						font-size: .68rem;
						@include breakpoint(huge) {
							font-size: .8rem;
						}
					}

					&.secondMetaLeft {
						@include breakpoint(giant) {
							display: none;
						}
					}
				}
				.metaColOneInnerTwo {
					padding: 0 $baseGap;
					align-items: flex-start;
					@include breakpoint(giant) {
						padding-left: .25rem;
					}
					@include breakpoint(huge) {
						padding: 0 $baseGap;
					}
				}
			}
			.metaColTwo {
				display: flex;
				justify-content: center;
				a {
					display: flex;
					justify-content: center;
					.branding {
						max-width: 150px;
						width: auto;
					}
				}
			}
			.metaColThree {	
				display: flex;
				align-items: center;
				span {
					display: block;
				}
				.metaColThreeInnerOne {
					align-items: flex-start;
					.metaMap {
						font-size: .75rem;
						@include breakpoint(huge) {
							font-size: .9rem;
						}
						&::before {
							content: map-get($iconMap, map);
							font-family: $iconFont;
							padding-right: .5rem;
							vertical-align: middle;
						}
					}
					.metaMapAdd {
						text-indent: 1rem;
						font-size: .68rem;
						@include breakpoint(huge) {
							font-size: .8rem;
						}
					}

					&.secondMetaRight {
						display: none;
						@include breakpoint(giant) {
							display: block;
						}
					}
				}
				.large-2 {
					display: flex;
					justify-content: flex-end;
					align-items: center;
					.metaFacebookText {
						span {
							display: none;
						}
					}
					.metaFacebook {
						width: rem(48px);
						height: rem(49px);
						max-width: rem(48px);
						max-height: rem(49px);
						opacity: 0.8;
						transition: .3s ease-in-out;
						&:hover {
							opacity: 1;
						}
					}
				}
			}
		}
		nav {
			ul {
				&.navi {
					min-height: 0 !important;
					flex-direction: row;
					padding-top: 0;
					li {
						&.anfahrt, &.impressum, &.datenschutzerklaerung {
							display: none;
						}
						a {
							color: $light;
							font-size: rem(18px);

							@include breakpoint(giant) {
								font-size: 1.2rem;
							}

							@include breakpoint(huge) {
								font-size: 1.5rem;
							}
							&.startseite {
								padding-left: 0;
							}
						}

						&.nav-back {
						}
					}
				}
			}
		}
	}
}

// Quickbar Styles
////
#quickbar {
	@extend %animatedTransform;
	background-color: $barBg;
	color: $barColor;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: rem($baseGap);
	z-index: 1000;
	box-shadow: 0px 20px 25px -15px rgba(0,0,0,0.75);

	@include breakpoint($break) {
		display: none;
	}
	.quickbarBranding {
		max-height: 100px;
		width: auto;
	}
}
